<div class="page_second" align="center">
    <div>
        <br/>
        <img src="../assets/cogelys_logo2b.png" alt="cogelys-logo">
        <h1 class="text-title"> Cogelys, votre partenaire conseil, gestion et recouvrement </h1>
        <ul class="navi" align="center">
            <li class="nav-itemed">
                <a class="nav-linked" [routerLinkActive]="'nav-activated-link'" routerLink="Accueil">
                    <h3>Accueil</h3>
                </a>
            </li>
            <li class="nav-itemed">
                <a class="nav-linked" [routerLinkActive]="'nav-activated-link'" routerLink="A_propos">
                    <h3>À propos</h3>
                </a>
            </li>
            <li class="nav-itemed">
                <a class="nav-linked" [routerLinkActive]="'nav-activated-link'" routerLink="Partenaires">
                    <h3>Nos partenaires</h3>
                </a>
            </li>
            <li class="nav-itemed">
                <a class="nav-linked" [routerLinkActive]="'nav-activated-link'" routerLink="Contact">
                    <h3>Nous contacter</h3>
                </a>
            </li>
        </ul>
    </div>
    <div class="page">
        <table align="center" width="60%">
            <div class="row">
                <div class="col-xs-12">
                    <div class="gap-50"></div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </table>
    </div>
    <div class="gap-200"></div>
    <div class="nav-itemed only-link-line">
        <a class="nav-linked" routerLink="Mentions_légales">Mentions légales et RGPD</a>
    </div>
</div>
