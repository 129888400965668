import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor() { }

  toggledElement: string = '';

  ngOnInit(): void {
  }

  toggleElement(selectedElement: string): void {
  this.toggledElement = selectedElement;
  }

  // isThisSelected(element:string): boolean {
  // 	let retour = false;
  // 	if(this.toggledElement==element) retour = true;
  // 	return retour;
  // }

  // getOnTimeToggledElement(): string {
  // 	return this.toggledElement;
  // }

}
