<h4 class="text-head">Nous sommes joignable par les biais suivants :</h4>
<ul>
    <li class="text-middle">
        Courrier : 100 Rue Louis Blanc, Les Marches de l'Oise, Bâtiment Vilnius, 60160 MONTATAIRE
    </li>
    <li class="text-middle">
        Téléphone : +33 3 44 21 55 01
    </li>
    <li class="text-middle">
        Mail : cogelys@cogelys.fr
    </li>
    <li class="text-middle">
        Fax : +33 3 44 21 27 97
    </li>
</ul>
