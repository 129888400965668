import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { UsComponent } from './us/us.component';
import { TrustComponent } from './trust/trust.component';
import { ContactComponent } from './contact/contact.component';                    //Partenaires / "ils nous font confiance"

const appRoutes: Routes = [
{ path:'Accueil', component: HomeComponent},
{ path:'A_propos', component: UsComponent},
{ path:'Partenaires', component: TrustComponent},
{ path:'Mentions_légales', component: LegalNoticeComponent},
{ path:'Contact', component: ContactComponent},
{ path:'', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
