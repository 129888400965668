import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trust',
  templateUrl: './trust.component.html',
  styleUrls: ['./trust.component.scss']
})
export class TrustComponent implements OnInit {

  data: any = {
    datasets: [{
        data: [
            32,
            26,
            5,
            8,
            24,
            5
        ],
        backgroundColor: [
            "#71E749",
            "#FC7426",
            "#D8CC56",
            "#4085F4",
            "#E4314F",
            "#615A99"
        ],
        label: 'Répartition des contrats (%)'
    }],
    labels: [
        "Industries",
        "Services à la personne",
        "Experts comptables",
        "Informatique",
        "Diagnostiqueurs immobiliers",
        "Autres"
    ]
  };

  options: any = {
    title: {
      display: true,
      text: 'Répartition des contrats (%)',
      fontSize: 26
    },
    legend: {
      position: 'bottom',
    }
  };

  constructor() { }

  ngOnInit(): void {  }
}
