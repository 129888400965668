import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FieldsetModule } from 'primeng/fieldset';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ChartModule } from 'primeng/chart';

import { AppRoutingModule } from './app-routing.module';                      // routes et liens
import { AppComponent } from './app.component';                              // app
import { HomeComponent } from './home/home.component';                        // accueil
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';  // mentions légales
import { UsComponent } from './us/us.component';                              // À propos de cogelys + infos activités
import { TrustComponent } from './trust/trust.component';
import { ContactComponent } from './contact/contact.component';                    // Partenaires / "ils nous font confiance"

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LegalNoticeComponent,
    UsComponent,
    TrustComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    NgxChartsModule,
    FieldsetModule,
    ScrollPanelModule,
    AppRoutingModule,
    ChartModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
