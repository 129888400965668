<div style="text-align: center;">
    <h3 class="text-head"> Bienvenue sur le site de COGELYS </h3>
    <h4 class="text-head"> Conseil en management et en gestion de la relation tiers - recouvrement de créances </h4>
</div>
<div class="gap-50"></div>
<div class="table-responsive">
    <table align="center">
        <tr>
            <th>
                <div class="embed-responsive forme-accueil">
                    <p> Recouvrement de créances </p>
                    <ul>
                        <li> Une équipe de professionnels spécialistes du Droit, gère les créances civiles et commerciales qui nous sont confiées. </li>
                        <li> Par une gestion personnalisée et un suivi très rigoureux, nous préservons votre image de marque et recouvrons vos créances, toujours dans le plus strict respect des individus et du droit. </li>
                    </ul>
                </div>
            </th>
            <th>
                <div class="forme-accueil invisible">
                </div>
            </th>
            <th>
                <div class="embed-responsive forme-accueil">
                    <p style="width: 100px;"> Gestion de poste client </p>
                    <ul>
                        <li> Après intégration de votre encours dans une base de données dédiée, notre gestionnaire SOUS le nom de votre société prendra contact avec vos clients, dans le cadre d’une démarche qualité, afin d’obtenir des engagements de paiement.
                        </li>
                        <li> Ces informations vous seront transférées au rythme contractuel, et vous permettront d’avoir une vue très précise des règlements devant arriver sous quinzaine, tout en prenant en compte vos conditions générales de vente et les moyens
                            de paiement de vos clients.
                        </li>
                        <li>
                            La détection de litige en amont de l’échéance de paiement vous permet de les résoudre et d’éviter le report du règlement, tout en garantissant la préservation de vos relations commerciales.
                        </li>
                    </ul>
                </div>
            </th>
        </tr>
        <tr>
            <th>
                <div class="forme-accueil invisible">
                </div>
            </th>
            <th>
                <div class="embed-responsive forme-accueil">
                    <p> Déontologie </p>
                    <ul>
                        <li> Garantie de confidentialité et préservation de vos relations commerciales indiquées en clair dans nos contrats. </li>
                        <li> Respect de votre image de marque. </li>
                        <li> Garantie de reversement des fonds reçus en votre nom, existence de comptes séquestres pour les encaissements.</li>
                        <li> Assurance Responsabilité Civile Professionnelle. </li>
                        <li> Registre RGPD recensant l’ensemble de traitements des données. </li>
                    </ul>
                </div>
            </th>
            <th>
                <div class="forme-accueil invisible">
                </div>
            </th>
        </tr>
        <tr>
            <th>
                <div class="embed-responsive forme-accueil">
                    <p> Recouvrement judiciaire </p>
                    <ul>
                        <li> Une équipe spécialiste du Droit expertise chaque dossier proposé en recouvrement judiciaire, elle sera donc capable de vous proposer la procédure la plus adaptée et pourra vous guider vers les auxiliaires de justice (avocats, huissiers)
                            correspondant à la situation. </li>
                    </ul>
                </div>
            </th>
            <th>
                <div class="forme-accueil invisible">
                </div>
            </th>
            <th>
                <div class="embed-responsive forme-accueil">
                    <p> Recrutement </p>
                    <ul>
                        <li> Vous souhaitez recruter : confiez nous le profil du poste à pourvoir et nous nous chargerons de son recrutement (garantie de 6 mois). </li>
                        <li> Vous souhaitez intégrer notre équipe : envoyez-nous votre lettre de candidature et CV sur notre <a href="mailto:cogelys@cogelys.fr"> adresse mail </a> dédiée. </li>
                    </ul>
                </div>
            </th>
        </tr>
    </table>
</div>
