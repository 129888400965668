<h4 class="text-head">Informations légales</h4>
<p class="text-middle"> Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l'Économie Numérique, nous vous informons que :</p>
<ul>
    <li>
        <p class="text-middle">
            Le présent site web est édité par :
            <br/> COGELYS SAS,
            <br/> 100 RUE LOUIS BLANC LES MARCHES DE L'OISE,
            <br/> BATIMENT VILNIUS 60160 MONTATAIRE
            <br/> France
            <br/> <br/> Capital social : 18 000 € ; RCS de Compiègne : 830 437 315 ; SIRET : 830 437 315 00013
            <br/> Numéro TVA intracommunautaire : FR41830437315
            <br/> Adresse de courrier électronique : cogelys@cogelys.fr
            <br/> Tél. : +33 3 44 21 55 01 - Fax : +33 3 44 21 27 97
        </p>
    </li>
    <li>
        <p class="text-middle">
            Le directeur de la publication du site web "www.cogelys.fr" est Monsieur Jean Lenoir en sa qualité de Président.
        </p>
    </li>
    <li>
        <p class="text-middle">
            Le prestataire assurant le stockage direct et permanent est : www.amen.fr
            <br/> AMEN SAS,
            <br/> 12-14 Rond Point des Champs Elysées
            <br/> 75008 Paris
            <br/> <br/> RCS : 421 527 797 000 11
            <br/> Société au capital social de 37 000 €. Directeur de la publication : Alain Closson.
            <br/> Numéro de téléphone : 01 70 99 53 41
        </p>
    </li>
    <li>
        <p class="text-middle">
            Le présent site web n'utilise pas de cookies, et n'a pour vocation ni collecte, ni utilisation, ni diffusion de données à caractère personnel.
        </p>
    </li>
</ul>
<div class="gap-50"></div>
<h4 class="text-head"> Charte de protection des données personnelles </h4>
<p class="text-middle">
    La société COGELYS SAS, dans le cadre de son activité, est amenée à traiter des données personnelles concernant des personnes physiques, salarié(e)s ou tiers divers, pour les besoins de sa gestion interne et également pour la fourniture de prestations
    de services auprès d'autres entreprises.
    <br/> <br/> Soucieuse de l'obligation de protection des données personnelles, la société s'engage en sa qualité de responsable de traitements, à utiliser les données personnelles collectées dans le respect de la législation relative au traitement
    de données personnelles et en particulier du Règlement Général sur la Protection des Données (RGPD).
    <br/> <br/> Les personnes physiques concernées, salarié(e)s ou autres tiers en relation avec COGELYS SAS, sont informés que les données à caractère personnel les concernant font l'objet de traitements permettant exclusivement le respect d'obligations
    contractuelles et réglementaires ou la réalisation de prestations de services définies par contrat avec ses clients.
    <br/> Les données collectées sont transmises à la société par ses clients ou par les personnes qu'elles concernent, dans le cadre de l'exécution de contrats; aucune collecte d'information n'est réalisée au moyen du site web de la société.
    <br/> Aucune utilisation de ces données n'est faite par la société au-delà de l'exécution de contrats; lesdites données sont seulement destinées à la société et à ses mandataires, et aucune donnée personnelle n'est transmise à des tiers commerciaux
    ou publicitaires.
    <br/> <br/> La société s'engage à mettre en oeuvre les moyens techniques et organisationnels appropriés pour s'assurer de la conformité de ses traitements à la législation relative à la protection des données personnelles. Les données personnelles
    sont traitées en France et ne sont en aucun cas transférées ni stockées en-dehors de l'Union Européenne.
    <br/> Elles sont conservées pendant toute la durée d'exécution du contrat dont elles dépendent, et au-delà, uniquement afin de respecter les obligations légales et d'assurer un archivage permettant la gestion éventuelle de contentieux.
    <br/> <br/> Les personnes physiques concernées, salarié(e) ou autres tiers, disposent d'un droit d'accès, de rectification, d'effacement de leurs données personnelles, d'opposition ou de suppression de données qui se révèleraient incomplètes, inadaptées,
    erronées ou obsolètes.
    <br/> Ce droit peut s'exercer à tout moment en s'adressant à la société, par courrier, ou par email adressé à cogelys@cogelys.fr à l'attention du DPO.
    <br/> En application du RGPD, toute demande devra être accompagnée d'un document signé prouvant l'identité du demandeur; une réponse lui sera adressée dans un délai de 30 jours suivant la réception de la demande. La société s'engage à détruire la
    preuve fournie à l'appui de la demande, dès que ladite demande est traitée.
    <br/> <br/> Conformément à la règlementation en vigueur, les personnes concernées disposent également du droit d'introduire une réclamation auprès d'une autorité de contrôle si elles considèrent que le traitement de données à caractère personnel les
    concernant constitue une violation de leurs droits.
    <br/> <br/> En France, l'autorité de contrôle est la Commission Nationale de l'Informatique et des Libertés, situé :
    <br/> 3 place de Fontenoy,
    <br/> 75007 PARIS
</p>
